import React, { useState ,useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from '../Components/Navbar'
import img from '../Assets/validbn.png'
import img2 from '../Assets/image 110.png'
import img3 from '../Assets/valid (1).png'
import img4 from '../Assets/valid (2).png'
import Footer from '../Components/Footer'
import { Modal ,ModalBody ,ModalHeader } from 'reactstrap';

function DataVaild() {
       const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Get form data
    const name = form.current.user_name.value.trim();
    const mobile = form.current.user_mobile.value.trim();
    const email = form.current.user_email.value.trim();
    const option = form.current.user_option.value.trim();
    const message = form.current.user_message.value.trim();

    // Form validation
    if (!name) {
      window.alert("Name is required");
      return;
    }

    if (!mobile) {
      window.alert("Mobile number is required");
      return;
    }

    if (!/^\d{10}$/.test(mobile)) {
      window.alert("Please enter a valid 10-digit mobile number");
      return;
    }

    if (!email) {
      window.alert("Email is required");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      window.alert("Please enter a valid email address");
      return;
    }

    if (!option) {
      window.alert("Please select an option");
      return;
    }

    if (!message) {
      window.alert("Message is required");
      return;
    }

    // If validation passes, send the email
    emailjs
      .sendForm('service_f3vhlz3', 'template_0fj88re', form.current, {
        publicKey: 'aBpUGhBmYAwpD_7Cz',
      })
      .then(
        () => {
          form.current.reset();
          window.alert('Thank you for submitting your message!');
        },
        (error) => {
          window.alert('Oops! Something went wrong. Please try again.');
        }
      );
  };
  const [modal , setModal] = useState(false)
  const toggleModal = () => setModal(!modal);
  return (
    <>
    <Navbar/>
    <div className='about-banner about-banner-plain'>
<div className='about-banner-left crm'>
<h4><span>Data Validation</span></h4>
<br/>
<p>Get your data validated to know what exactly your data speaks. 
We follow a systematically designed set process to validate your data information, this rigorous compliance check ensures the quality & accuracy of your stored data. In a validation process your data is filtered through multi-layer quality checks, compared & verified with the most reliable data channels sources & on the final stage its validated over the phone by our expert validation team to gain optimum results. 
</p>
<br/>
<br/>
<button onClick={toggleModal}>Try For Free - Get Your Data Validated</button>
</div>
<div className='about-banner-right'>

<img src={img} data-aos="flip-up"/>
</div>

    </div>

    <div className='targeted'>
    <div className='targeted-div'>
    <div className='targeted-left'>
    <h2>Web Verified</h2>
    
    <br/>
    <p>Web-based research activity is conducted to validated & verify data based on the information available on the internet, mainly through reliable data channels, online forums, publications and other social platforms.</p>
    <br/>
    {/* <button onClick={toggleModal}>Try For Free - Get Your Customized List </button> */}
</div>
 <div className='targeted-right'>
 <img src={img3} data-aos="zoom-in"/>

</div>
</div>
 <div className='targeted-div targeted-div2'>
 <div className='targeted-right targeted-right2'>
 <img src={img4} data-aos="zoom-in"/>

</div>
    <div className='targeted-left'>
    <h2>Phone Verified</h2>
    
    <br/>
    <p>Everything from web verification plus each data set is verified & confirmed over phone to optimize the data accuracy. </p>
    <br/>
    {/* <button onClick={toggleModal}>Try For Free - Get Your Customized List </button> */}
</div>

</div>
<Footer/>
  
    </div>

    <Modal size="lg" isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
      Experience The Change, That Accelerate Your ROI
      </ModalHeader>
      <ModalBody className='modal-a'>
      <form ref={form} onSubmit={sendEmail}>

       <div className='modal-ips'>
      <div className='mpdal-ip'>
      <label>Name</label>
       <br/>
       <input name='user_name' placeholder='Enter Your Name' />
      </div>
     
       <div  className='mpdal-ip'>

       <label>Title</label>
       <br/>
       <input name='user_title' placeholder='Enter Your Title' />
       </div>

       </div>
<div className='modal-ips'>
<div className='mpdal-ip'>
<label>Phone</label>
       <br/>
       <input name='user_mobile' placeholder='9874563210' />
</div>
       
       <div className='mpdal-ip'>

       <label>Email</label>
       <br/>
       <input name='user_email' placeholder='Enter Your Email' />
       </div>

</div>

<br/>
<div className='modal-ips'>

       
       <div className='mpdal-ip'>

       <label for="options">Choose an option:</label>
       <br/>
     
<select id="options" name='user_option'>

            <option value=''>--Please select an option--</option>
            <option value='Data Research'><li>Data Research</li></option>
            <option value='Market Research'>- Market Research</option>

            <option value='Targeted Account List'>Targeted Account List</option>
            <option value='Prospect List'>Prospect List</option>
            <option value='Technographics'>Technographics</option>
        





            <option value='CRM Cleansing'>- CRM Cleansing</option>

            <option value='Data Append'>Data Append</option>
            <option value='Data Enrichment'>Data Enrichment</option>
            <option value='Data Unification'>Data Unification</option>




            <option value='Data Validation'>- Data Validation</option>
            <option value='Web Verified'>Web Verified</option>
            <option value='Phone Verified'>Phone Verified</option>



            <option value='Demand Generation'><li>- Demand Generation</li></option>
            <option value='Virtual Assistanc'>- Virtual Assistance</option>
       


</select>
       </div>

</div>
<br/>
       
      

       <label>Messages</label>
       <br/>
       <input name='user_message' placeholder='Enter Your Messages' />
       <br/>
       <button type="submit">Submit</button>
       </form>

      </ModalBody>
    </Modal>
   </>
  )
}

export default DataVaild