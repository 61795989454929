import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../Assets/newlogo.svg';

function Footer() {
  return (
    <div className='footer-div'>
    <div className='footer'>
    <div className='f-left'>
    <img src={logo} alt="Logo" />

    <br/>
    <br/>

    <p>Outsmart Your Product Outreach With Our Simplified <br/>Sales & Marketing Solutions</p>
    <br/>

    <ul>
       
        <Link className="none" to='/dataresearch'>

<li>Market Research</li>
</Link>
 <Link className="none" to='/crmcleansing'>

<li>CRM Cleansing</li>
</Link>
 <Link className="none" to='/supply'>

<li>Demand Genration</li>
</Link>
 <Link className="none" to='/support'>

<li>Virtual Assistance</li>
</Link>


<Link className="none" to='/privacy'>

<li>Privacy</li>
</Link>
        </ul>
    </div>
    <div className='f-right'>
    <p>Stay up-to-date with our latest research<br/> strategies & insights.</p>
    <br/>
    <br/>
    <div className='f-inps'>
    <input placeholder='Email'/>
    <button>Subscribe</button>

    </div>

    </div>
    </div>
    <br/>
    <br/>
  

    <div className='foot-bottom'>
    <p>© 2023 VIBRANT. All rights reserved.</p>
    <Link  className='none2' to='https://www.strixdigital.in/'>

    <p >Designed & Developed by <span style={{fontWeight:'800'}}>Strix Digital</span></p>
    </Link>

    </div>

    </div>
  )
}

export default Footer