import React, { useState ,useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from '../Components/Navbar'
import img from '../Assets/recherbn.png'
import img2 from '../Assets/gradient-crm-illustration.png'
import img3 from '../Assets/Bespoke.png'
import img4 from '../Assets/Group 648.png'
import img5 from '../Assets/Group 645.png'

import Footer from '../Components/Footer'
import { Modal ,ModalBody ,ModalHeader } from 'reactstrap';

function DataResearch() {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Get form data
    const name = form.current.user_name.value.trim();
    const mobile = form.current.user_mobile.value.trim();
    const email = form.current.user_email.value.trim();
    const option = form.current.user_option.value.trim();
    const message = form.current.user_message.value.trim();

    // Form validation
    if (!name) {
      window.alert("Name is required");
      return;
    }

    if (!mobile) {
      window.alert("Mobile number is required");
      return;
    }

    if (!/^\d{10}$/.test(mobile)) {
      window.alert("Please enter a valid 10-digit mobile number");
      return;
    }

    if (!email) {
      window.alert("Email is required");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      window.alert("Please enter a valid email address");
      return;
    }

    if (!option) {
      window.alert("Please select an option");
      return;
    }

    if (!message) {
      window.alert("Message is required");
      return;
    }

    // If validation passes, send the email
    emailjs
      .sendForm('service_f3vhlz3', 'template_0fj88re', form.current, {
        publicKey: 'aBpUGhBmYAwpD_7Cz',
      })
      .then(
        () => {
          form.current.reset();
          window.alert('Thank you for submitting your message!');
        },
        (error) => {
          window.alert('Oops! Something went wrong. Please try again.');
        }
      );
  };
    const [modal , setModal] = useState(false)
    const toggleModal = () => setModal(!modal);
  return (
   <>
      <Modal size="lg" isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
      Experience The Change, That Accelerate Your ROI 
      </ModalHeader>
      <ModalBody className='modal-a'>
      <form ref={form} onSubmit={sendEmail}>

       <div className='modal-ips'>
      <div className='mpdal-ip'>
      <label>Name</label>
       <br/>
       <input name='user_name' placeholder='Enter Your Name' />
      </div>
     
       <div  className='mpdal-ip'>

       <label>Title</label>
       <br/>
       <input name='user_title' placeholder='Enter Your Title' />
       </div>

       </div>
<div className='modal-ips'>
<div className='mpdal-ip'>
<label>Phone</label>
       <br/>
       <input name='user_mobile' placeholder='9874563210' />
</div>
       
       <div className='mpdal-ip'>

       <label>Email</label>
       <br/>
       <input name='user_email' placeholder='Enter Your Email' />
       </div>

</div>

<br/>
<div className='modal-ips'>

       
       <div className='mpdal-ip'>

       <label for="options">Choose an option:</label>
       <br/>
     
<select id="options" name='user_option'>
 
            <option value=''>--Please select an option--</option>
            <option value='Data Research'><li>Data Research</li></option>
            <option value='Market Research'>- Market Research</option>

            <option value='Targeted Account List'>Targeted Account List</option>
            <option value='Prospect List'>Prospect List</option>
            <option value='Technographics'>Technographics</option>
        





            <option value='CRM Cleansing'>- CRM Cleansing</option>

            <option value='Data Append'>Data Append</option>
            <option value='Data Enrichment'>Data Enrichment</option>
            <option value='Data Unification'>Data Unification</option>




            <option value='Data Validation'>- Data Validation</option>
            <option value='Web Verified'>Web Verified</option>
            <option value='Phone Verified'>Phone Verified</option>



            <option value='Demand Generation'><li>- Demand Generation</li></option>
            <option value='Virtual Assistanc'>- Virtual Assistance</option>
       


      
</select>
       </div>

</div>
<br/>
       
      

       <label>Messages</label>
       <br/>
       <input name='user_message' placeholder='Enter Your Messages' />
       <br/>
       <button type="submit">Submit</button>
       </form>

      </ModalBody>
    </Modal>
    <Navbar/>
    <div className='about-banner about-banner3 about-banner-plain'>
<div className='about-banner-left'>
<h4>Not Just Data - <span>An <del>Artificial</del> Intelligent </span> Human Verified Research Insight That <span>Helps You Get Connected</span> With The Right Prospect At The  <span>Right Time</span>.

</h4>
<br/>
<p>Each & every single record that we deliver to our client is freshly crafted & systematically processed through layers of quality audits in our research center. It’s precisely tailored & designed to match your desired campaign requirements. Since everything is freshly sourced our data is enriched with relevant & highly actionable insights, as a result it directly connects you with your ideal prospect. This simply helps us deliver a real time prospect insights & effectively eliminate the non-productive hours of your sales team.</p>
<br/>

<h5>We have further simplified our innovative services to exactly align with your business requirements. </h5>

</div>
<div className='about-banner-right'>

<img src={img} data-aos="zoom-in"/>
</div>

    </div>
    <div className='research'>
    <div className='research-left'>
    <img src={img2} data-aos="zoom-in"/>


    </div>
    <div className='research-right' data-aos="zoom-in">
    <h2>Market Research</h2>
    <h3><i>Stay ahead of the game, know them from head to toe </i></h3>
    <br/>
    <p>Simply share your product details & we’ll craft your ideal GTM ready data list for your sales & marketing campaigns. Our market research covers the complete process right from identifying your total addressable market to delivering qualified leads. This freshly crafted list is enriched with 100% accurate & complete prospect insights for your ongoing campaigns. </p>
    <br/>
<button onClick={toggleModal}>Try For Free  </button>
    </div>

    </div>
    <div className='targeted'>
    <div className='targeted-div'>
    <div className='targeted-left'>
    <h2>Targeted Account List</h2>
    <h3><i>Add exponential value to your marketing campaigns</i></h3>
    <br/>
    <p>Simply share your targeted industry & We’ll craft your ideal account list. Successful B2B company includes account-based marketing as a means to generate revenue among other types of marketings. One of the key aspects of marketing is defining your audience and creating a persona of ideal customers. We can help you with extracting the list of your ideal customers (accounts) based on filters like Industry, company size, revenue, geography, technologies, etc.</p>
    <br/>
    <button onClick={toggleModal}>Try For Free - Get Your Customized List </button>
</div>
 <div className='targeted-right'>
 <img src={img3} data-aos="zoom-in"/>

</div>
</div>

 <div className='targeted-div targeted-div2'>
 <div className='targeted-right targeted-right2'>
 <img src={img4} data-aos="zoom-in"/>

</div>
    <div className='targeted-left'>
    <h2>Prospect List </h2>
    <h3><i>Be real get real, get directly connected to decision makers </i></h3>
    <br/>
    <p>Simply share your targeted accounts & We’ll craft your ideal prospect list. This freshly crafted list is enriched with 100% accurate & complete prospect insights for your Sales campaigns. Our list supplements your team with the right information at the right time. (Prospect Direct Mobile Numbers)  </p>
    <br/>
    <button onClick={toggleModal}>Try For Free - Get Your Customized List </button>
</div>

</div>
 <div className='targeted-div'>
    <div className='targeted-left'>
    <h2>Technographics </h2>
    <h3><i>Market insights right at your finger tips</i></h3>
    <br/>
    <p>Technographics is such a GTM game changer, always keeps you ahead of the curve by supplementing your team with ICP aligned accounts. know your customer right from their tech stack to their day-to-day business requirements.   </p>
    <br/>
    {/* <button onClick={toggleModal}>Try For Free - Get Your Customized List </button> */}
</div>
 <div className='targeted-right'>
 <img src={img5} data-aos="zoom-in"/>

</div>
</div> 

    </div>
    <Footer/>
   </>
  )
}

export default DataResearch