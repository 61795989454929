import React from 'react'
import Navbar from '../Components/Navbar'
import img from '../Assets/supplybn.png'
import Footer from '../Components/Footer'

function Supply() {
  return (
    <>
        <Navbar/>
        <div className='about-banner about-banner-plain'>
<div className='about-banner-left'>
<h4>It's All About <span>Demand & Supply</span> - We Help You Outsmart <span>Your Product Outreach & Directly</span> Connect You With Your Targeted Audience

</h4>
<br/>
<p>We infuse your pipeline with sales qualified leads and allows your team to directly engage with your targeted audience. This strategic approach eliminates the non-productive hours of your team involved in researching the right audience & effectively helps them focus, corely on the sales engagements</p>
<br/>

</div>
<div className='about-banner-right'>

<img src={img} data-aos="zoom-in"/>
</div>

    </div>
    <Footer/>
    </>
  )
}

export default Supply