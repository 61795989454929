import React from 'react'
import Navbar from '../Components/Navbar'
import img from '../Assets/Group 643.png'
import img2 from '../Assets/hand-drawn-biodiversity-illustration_23-2149400543 1.png'

import Footer from '../Components/Footer'

function GreenRevolution() {
  return (
   <>
    <Navbar/>
    <div className='about-banner about-banner2'>
<div className='about-banner-left about-banner-left2'>
<h1><span>LIVE GREEN<br/> BREATHE CLEAN!</span></h1>

<h4 className='joingren'><span>Join The Green Revolution</span></h4>

<br/>
<p></p>

</div>
<div className='about-banner-right'>

<img src={img} data-aos="zoom-in"/>
</div>

    </div>
    <div className='center'>
        <h3 >We are on the forefront to deliver a revolutionary movement by planting 10 million trees & contribute towards the much-needed global commitments.</h3>
    </div>
    <div className='GreenRevolution'>
    <div className='GreenRevolution-left'>
<img src={img2} data-aos="zoom-in"/>
</div>
 <div className='GreenRevolution-right'>
 <p>This revolutionary forest landscape restoration (FLR) project aims to regain the lost biodiversity & enhance ecological functionality of our mother earth. This movement is to develop diverse, productive and multifunctional landscapes that are resilient in the face of economic fluctuations and climatic change. </p>
<h6>We still in the designing & planning stage & would love to connect with experts who are mainly into Forest Landscape Restoration & Green Life Conservation.</h6>
</div>

    </div>
    {/* <div className='GreenRevolution'>
    <div className='GreenRevolution-right'>
 <p></p>

</div>
    <div className='GreenRevolution-left'>
<img src={img3} data-aos="zoom-in"/>
</div>


    </div> */}
<Footer/>
   </>
  )
}

export default GreenRevolution