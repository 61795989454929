import React, { useState } from 'react';

import img from '../Assets/carrer1.png'
import about from '../Assets/carrer2.png'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';

function Career() {
      // State to track which accordion item is open
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  // Functions to toggle accordion items
  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };

  return (
<>
    <Navbar/>
    <div className='about-banner about-banner-plain about-banner-carrer'>
<div className='about-banner-left crm'>
<h1><span>Join Us ! </span></h1>
<br/>
<h2>It's aways a win-win here,<br/>We Win - You Win</h2>
<p></p>
<br/>
<br/>

</div>
<div className='about-banner-right'>

<img src={img} data-aos="fade-down"/>
</div>

    </div>


    <div className='about-div'>
    <div className='about-div-left'>

<h2 data-aos="zoom-in">We Are <span>Completely Digital</span>- Freedom to Spend <span>More Time With</span> Your Loved Ones.</h2>


</div>
    <div className='about-div-right'>
<img data-aos="zoom-in" src={about}/>
</div>


</div>
<div className='accod'>

      {/* Accordion Item 1 */}
      <div className="accordion-item">
        <div className="accordion-title" onClick={toggleAccordion2}>
          <h3>Head of Sales</h3>
          <div className='ac1'>
            <p>Job Loacation</p>
            <h4>Remote | Global</h4>
          </div>
          <div className='ac1'>
            <p>Posted On</p>
            <h4>Jan,2023</h4>
          </div>
          
          <span><h5>{isOpen2 ? '-' : '+'}</h5></span>
        </div>
        {isOpen2 && (
          <div className="accordion-content">
          <h6>Job description</h6>
          <br/>
          <p>Bitcoin is one of the most popular cryptocurrencies in the market. First introduced in 2009 by Satoshi Nakamoto, Bitcoin has held the crypto market’s number one spot according to market capitalization. Bitcoin paved the way for many existing altcoins in the market and marked a pivotal moment for digital payment solutions.  
</p>
          <br/>
          <h6>Job Responsibilities</h6>
          <br/>
          <p>Binance is the global blockchain company behind the world’s largest digital asset exchange by trading volume and users, serving a greater mission to accelerate cryptocurrency adoption and increase the freedom of money.Are you looking to be a part of the most influential company in the blockchain industry and contribute to the crypto-currency revolution that is changing the world?</p>
          <br/>
<ul>
    <li>Engage with developer communities and project teams to generate leads and new business opportunities</li>

<li>Collaborate across teams to achieve business objectives</li>

<li>Manage the entire sales lifecycle from prospecting, to deal negotiation, closing, including renewals and upsales.
</li>
</ul>
          </div>
        )}
      </div>
      <div className="accordion-item">
        <div className="accordion-title" onClick={toggleAccordion1}>
          <h3>Business Associate</h3>
          <div className='ac1'>
            <p>Job Loacation</p>
            <h4>Remote | Global</h4>
          </div>
          <div className='ac1'>
            <p>Posted On</p>
            <h4>Jan,2023</h4>
          </div>
          
          <span><h5>{isOpen1 ? '-' : '+'}</h5></span>
        </div>
        {isOpen1 && (
          <div className="accordion-content">
          <h6>Job description</h6>
          <br/>
          <p>Bitcoin is one of the most popular cryptocurrencies in the market. First introduced in 2009 by Satoshi Nakamoto, Bitcoin has held the crypto market’s number one spot according to market capitalization. Bitcoin paved the way for many existing altcoins in the market and marked a pivotal moment for digital payment solutions.  
</p>
          <br/>
          <h6>Job Responsibilities</h6>
          <br/>
          <p>Binance is the global blockchain company behind the world’s largest digital asset exchange by trading volume and users, serving a greater mission to accelerate cryptocurrency adoption and increase the freedom of money.Are you looking to be a part of the most influential company in the blockchain industry and contribute to the crypto-currency revolution that is changing the world?</p>
          <br/>
<ul>
    <li>Engage with developer communities and project teams to generate leads and new business opportunities</li>

<li>Collaborate across teams to achieve business objectives</li>

<li>Manage the entire sales lifecycle from prospecting, to deal negotiation, closing, including renewals and upsales.
</li>
</ul>
          </div>
        )}
      </div>
     
      <div className="accordion-item">
        <div className="accordion-title" onClick={toggleAccordion3}>
          <h3>Subject Matter Expert</h3>
          <div className='ac1'>
            <p>Job Loacation</p>
            <h4>Remote | Global</h4>
          </div>
          <div className='ac1'>
            <p>Posted On</p>
            <h4>Jan,2023</h4>
          </div>
          
          <span><h5>{isOpen3 ? '-' : '+'}</h5></span>
        </div>
        {isOpen3 && (
          <div className="accordion-content">
          <h6>Subject Matter Expert</h6>
          <br/>
          <p>Bitcoin is one of the most popular cryptocurrencies in the market. First introduced in 2009 by Satoshi Nakamoto, Bitcoin has held the crypto market’s number one spot according to market capitalization. Bitcoin paved the way for many existing altcoins in the market and marked a pivotal moment for digital payment solutions.  
</p>
          <br/>
          <h6>Job Responsibilities</h6>
          <br/>
          <p>Binance is the global blockchain company behind the world’s largest digital asset exchange by trading volume and users, serving a greater mission to accelerate cryptocurrency adoption and increase the freedom of money.Are you looking to be a part of the most influential company in the blockchain industry and contribute to the crypto-currency revolution that is changing the world?</p>
          <br/>
<ul>
    <li>Engage with developer communities and project teams to generate leads and new business opportunities</li>

<li>Collaborate across teams to achieve business objectives</li>

<li>Manage the entire sales lifecycle from prospecting, to deal negotiation, closing, including renewals and upsales.
</li>
</ul>
          </div>
        )}
      </div>

     
   

</div>
<br/>
<Footer/>
</>
  )
}

export default Career