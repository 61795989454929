import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import logo from '../Assets/newlogo.svg';


function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const toggleDivRef = useRef(null);

  const openToggleDiv = () => {
    setIsDivVisible(true);
  };

  const closeToggleDiv = () => {
    setIsDivVisible(false);
  };

  const handleClickOutside = (event) => {
    if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
      closeToggleDiv();
    }
  };

  useEffect(() => {
    if (isDivVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDivVisible]);

  return (
    <>
      <nav>
        <div className='logo'>
          <Link className="none" to='/'>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <ul>
        <div className="dropdown">
  <Link className="none"  >
    <li>Solutions</li>
  </Link>
  <div className="dropdown-content"> 
  <div className="sub-dropdown">
      <Link><li>Data Research</li></Link>
      <div className="sub-dropdown-content">
        <Link to="/dataresearch"><li>Market Research</li></Link>
        <Link to="/crmcleansing"><li>CRM Cleansing</li></Link>
        <Link to="/datavalid"><li>Data Vaildation</li></Link>

      </div>
    </div>
    <Link to="/supply"><li>Demand Generation</li> </Link>
   
    <Link to="/support"><li>Virtual Assistance</li></Link>


  </div>
</div>
            <div className="dropdown">
  <Link className="none"  >
    <li>Resources</li>
  </Link>
  <div className="dropdown-content"> 

    <Link to="/process"><li>Data Processing Journey</li></Link>
    <Link to="/career "><li>Career </li></Link>
   
    
  </div>
</div>

<div className="dropdown">
  <Link className="none" >
    <li>Upcoming Events</li>
  </Link>
  <div className="dropdown-content"> 

    <Link><li>Corporate Events</li></Link>
    <div className="sub-dropdown">
      <Link ><li>Social Events</li></Link>
      <div className="sub-dropdown-content">
        <Link to="/greenrevolution"><li>Green Revolution </li></Link>
       

      </div>
    </div>
   
    
  </div>
</div>



          <Link  className="none" to='/about' >
            <li >About Us</li>
          </Link>
      
          
        </ul>
        <ul>
       
        <Link className="none" to='/contact' >
            <li>Login | Let’s Connect</li>
          </Link>
        
        </ul>
        <i
          style={{ color: 'rgba(112, 111, 211, 1)', cursor: 'pointer' }}
          className="bi bi-list"
          onClick={isDivVisible ? closeToggleDiv : openToggleDiv}
        ></i>
      </nav>
      {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
            <div className="dropdown">
  <Link className="none"  >
    <li style={{color:'white'}}>Solution</li>
  </Link>
  <div className="dropdown-content"> 
  <div className="sub-dropdown">
      <Link><li>Data Research</li></Link>
      <div className="sub-dropdown-content">
      <Link to="/dataresearch"><li>Market Research</li></Link>
        <Link to="/crmcleansing"><li>CRM Cleansing</li></Link>
        <Link to="/datavalid"><li>Data Vaildation</li></Link>

      </div>
    </div>
    <Link to="/supply"><li>Demand Generation</li> </Link>
   
   <Link to="/support"><li>Virtual Assistance</li></Link>


  </div>
</div>
<br/>
  <div className="dropdown">
  <Link className="none"  >
    <li style={{color:'white'}}>Resources</li>
  </Link>
  <div className="dropdown-content"> 

    <Link to="/process"><li >Data Processing Journey</li></Link>
    <Link to="/career "><li >Career </li></Link>
   
    
  </div>
</div>
          <br/>
          <div className="dropdown">
  <Link className="none" >
    <li style={{color:'white'}}>Upcoming Events</li>
  </Link>
  <div className="dropdown-content"> 

<Link><li>Corporate Events</li></Link>
<div className="sub-dropdown">
  <Link ><li>Social Events</li></Link>
  <div className="sub-dropdown-content">
    <Link to="/greenrevolution"><li>Green Revolution </li></Link>
   

  </div>
</div>


</div>
</div>

<br/>

<Link className="none" to='/about' >
            <li style={{color:'white'}}>About Us</li>
          </Link>
          <Link className="none" to='/contact' >
            <li style={{color:'white'}}>Login | Let’s Connect</li>
          </Link>
            <img src={logo} alt="Logo" />

            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
