import React, { useState ,useRef } from 'react';
import Navbar from '../Components/Navbar'
import img from '../Assets/contbn.png'
import img2 from '../Assets/Business support-rafiki 1.png'
import dot from '../Assets/yes-alt-svgrepo-com (1) 6.png'
import img3 from '../Assets/Contact us-rafiki 1.png'
import Footer from '../Components/Footer'
import emailjs from '@emailjs/browser';
function Contact() {
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      // Get form data
      const name = form.current.user_name.value.trim();
      const mobile = form.current.user_mobile.value.trim();
      const email = form.current.user_email.value.trim();
      const option = form.current.user_option.value.trim();
      const message = form.current.user_message.value.trim();
  
      // Form validation
      if (!name) {
        window.alert("Name is required");
        return;
      }
  
      if (!mobile) {
        window.alert("Mobile number is required");
        return;
      }
  
      if (!/^\d{10}$/.test(mobile)) {
        window.alert("Please enter a valid 10-digit mobile number");
        return;
      }
  
      if (!email) {
        window.alert("Email is required");
        return;
      }
  
      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        window.alert("Please enter a valid email address");
        return;
      }
  
      if (!option) {
        window.alert("Please select an option");
        return;
      }
  
      if (!message) {
        window.alert("Message is required");
        return;
      }
  
      // If validation passes, send the email
      emailjs
        .sendForm('service_f3vhlz3', 'template_0fj88re', form.current, {
          publicKey: 'aBpUGhBmYAwpD_7Cz',
        })
        .then(
          () => {
            form.current.reset();
            window.alert('Thank you for submitting your message!');
          },
          (error) => {
            window.alert('Oops! Something went wrong. Please try again.');
          }
        );
    };
  
  return (
   <>
    <Navbar/>
    <div className='about-banner about-banner-plain'>
    <h1><span>We're here</span> for you, drop us a line to <span>help us address your</span> requirements </h1>
<img data-aos="zoom-in" src={img}/>
    </div>
    <div className='showcase'>
    <h2><span>We’d love to</span> discuss and showcase <span>how our simplified solutions</span> can</h2>
<br/>
<br/>
<br/>
<div className='showcase-div'>
<div className='showcase-left'>
<img data-aos="zoom-in" src={img2}/>

</div>
<div className='showcase-right'>
<div className='roi'>
<ul>
    <li><img src={dot}/> Accelerate Your ROI</li>
    <li><img src={dot}/> Outsmart Your Product Outreach</li>
    <li><img src={dot}/> Connect You With Your ideal Prospects</li>
    <li><img src={dot}/> Enrich your CRM With Relevant Insights</li>
    <li><img src={dot}/> Optimize your sales & Marketing Performance</li>

</ul>
</div>

</div>
</div>
    </div>
    <div className='contact-form'>
    <div className='contact-form-left'>
    <form ref={form} onSubmit={sendEmail}>
        <div className='ips-div'>
            <div className='ips'>
                <label>Name</label>
                <input  name='user_name' placeholder='Your Name'/>
            </div>
            <div className='ips'>
                <label>Title</label>
                <input name='user_title' placeholder='Title'/>
            </div>
          
        </div>
        <br/>
        <div className='ips-div'>
            <div className='ips'>
                <label>E-mail</label>
                <input name='user_email' placeholder='Your E-mail'/>

            </div>
            <div className='ips'>
                <label>Mobile No.</label>
                <input name='user_mobile' placeholder='Your Mobile No.'/>
            </div>
          
          
        </div>
        <br/>
        <div className='ips-div'>
        <div className='ips '>
            <label>Select Option</label>
          <select name='user_option'>
            <option value=''>--Please select an option--</option>
            <option value='Data Research'><li>Data Research</li></option>
            <option value='Market Research'>- Market Research</option>

            <option value='Targeted Account List'>Targeted Account List</option>
            <option value='Prospect List'>Prospect List</option>
            <option value='Technographics'>Technographics</option>
        





            <option value='CRM Cleansing'>- CRM Cleansing</option>

            <option value='Data Append'>Data Append</option>
            <option value='Data Enrichment'>Data Enrichment</option>
            <option value='Data Unification'>Data Unification</option>




            <option value='Data Validation'>- Data Validation</option>
            <option value='Web Verified'>Web Verified</option>
            <option value='Phone Verified'>Phone Verified</option>



            <option value='Demand Generation'><li>- Demand Generation</li></option>
            <option value='Virtual Assistanc'>- Virtual Assistance</option>
       


          </select>
            </div>
            
          
        </div>
        <br/>

        <div className='ips-div'>
            <div className='ips'>
                <label>Message</label>
                <textarea name='user_message' placeholder='Message..'></textarea>
            </div>
            
          
        </div>
        <br/>
        <br/>
<button type="submit">Submit</button>
    </form>

</div>
   <div className='contact-form-right'>
<img src={img3} data-aos="zoom-in"/>
</div>
    </div>
    <Footer/>
   </>
  )
}

export default Contact