import React from 'react'
import Navbar from '../Components/Navbar'
import img from '../Assets/Group 6411.png'
import about from '../Assets/Investment data-amico 1.png'
import img2 from '../Assets/Group 64211.png'
import icon from '../Assets/innovation_10939054.png'
import icon2 from '../Assets/2.png'
import icon3 from '../Assets/3.png'
import icon4 from '../Assets/4.png'

import Footer from '../Components/Footer'
function About() {
  return (
   <>
    <Navbar/>
    <div className='about-banner about-banner-plain'>
    <h1>We Are Way <span>Beyond Good Data</span> - The Real Actionable Data </h1>
<img data-aos="zoom-in" src={img}/>
    </div>
    <div className='about-div'>
    <div className='about-div-right'>
<img data-aos="zoom-in" src={about}/>
</div>
<div className='about-div-left'>

<h2 data-aos="zoom-in"><span>We Are All About</span><br/> Prospecting & Growth</h2>
<br/>
<h4 data-aos="zoom-in">VIBRANT is an innovative sales acceleration agency, that simply helps you outsmart your product outreach. We supplement your sales & marketing team by building most relevant & highly actionable business insights for your business engagements. Our services are precisely designed to deliver true experience of actionable data, that simply brings market insights right at your fingertips. We facilitate brands ranging from early startups to leading multinationals, whether you are a marketing professional or a manufacturing industry we cover it all.

</h4>

</div>

</div>
<div className='prospecting'>
<div className='prospecting-left'>
<h2>Beyond Prospecting</h2>
<br/>
<p>We’re REAL – The real human intel which provide reliable services based on your business needs & consistently adhere to your desired specifications. Our tagline aptly describes our philosophy of taking utmost care of your business engagements. We have been trusted globally for our robust solutions that keep growing brands around the world!</p>

</div>
<div className='prospecting-right'>
<img src={img2} data-aos="zoom-in"/>

</div>
</div>
<div className='Empowered'>
<p></p>
    <h3>We Are Empowered By</h3>
    <div className='Innovation-card-div'>
        <div className='Innovation-card'>
        <div className='Innovation-icon'>

            <img src={icon}/>
        </div>

            <h4>Innovation</h4>
        </div>
        <div className='Innovation-card'>
        <div className='Innovation-icon'>

            <img src={icon2}/>
        </div>
            <h4>Reliability</h4>
        </div>
        <div className='Innovation-card'>
        <div className='Innovation-icon'>

            <img src={icon3}/>
        </div>

            <h4>Accuracy</h4>
        </div>
        <div className='Innovation-card'>
        <div className='Innovation-icon'>

            <img src={icon4}/>
        </div>

            <h4>Integrity</h4>
        </div>
    </div>

</div>
<Footer/>
   </>
  )
}

export default About