import React from 'react'
import Navbar from '../Components/Navbar'
import img from '../Assets/supportbn.png'
import tick from '../Assets/yes-alt-svgrepo-com (1) 11.png'
import Footer from '../Components/Footer'
function Support() {
  return (
    <>
    <Navbar/>
    <div className='about-banner about-banner-plain'>
<div className='about-banner-left'>
<h4>
We Are Everywhere - <span>Your Extended Integral Team</span> With Real  <span>Human Intelligence</span>, That Supports Your Customer 24x7  <span>Round The Clock</span>
</h4>
<br/>
<p>We are the best brains in the panel who speaks your language be it technical or personal. A reliable team of experts truly dedicated to provide complete customer satisfaction.</p>
<br/>
<ul>
   
    <li>
        <img className='tick'  src={tick}/>
        <i>Administrative Assistance</i>
    </li>
    <li>
        <img className='tick' src={tick}/>
       <i> Backend Support</i>
    </li>
    <li>
        <img className='tick' src={tick}/>
        <i> Customer Support (Chat & Voice)</i>
    </li>
</ul>

</div>
<div className='about-banner-right'>

<img src={img} data-aos="flip-right"/>
</div>

</div>

<Footer/>
</>
  )
}

export default Support