import React from "react";
import Navbar from "../Components/Navbar";
import img from "../Assets/main.png";
import about from "../Assets/Sales consulting-pana 1.png";
import icon from "../Assets/DRec.jpeg";
import icon2 from "../Assets/crmcli.png";
import icon3 from "../Assets/demand.png";

import pimg from "../Assets/partner Logo.png";
import profile from "../Assets/p1.jpg";
import profile2 from "../Assets/p2.jpg";

import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import c1 from '../Assets/purepng.com-blackrock-logologobrand-logoiconslogos-251519938910ako99.png'
import c2 from '../Assets/images.png'
import c3 from '../Assets/Ogilvy_Logo.png'
import c4 from '../Assets/Nestle-Logo.png'
import c5 from '../Assets/deloitte-digital.webp'
import c6 from '../Assets/ma-kyc-zycus-logo.png'

import bulb from '../Assets/Group 38.png'
import social1 from '../Assets/links (1).png'
import social2 from '../Assets/links (2).png'
import social3 from '../Assets/links (3).png'



function Home() {
  return (
    <>
      <Navbar />
      <div className="banner banner2">
        <h1 data-aos="zoom-in" >KNOW & GROW <img className="bulb" src={bulb}/></h1>
        <h3 data-aos="zoom-in">
        Your Targeted Audience with Our Simplified<br/> Sales & Marketing Solutions
          <br />
        </h3>

        
        <img className="home-banner" data-aos="zoom-in" src={img} />

        <div className="socialmedia">
  <a href="https://www.linkedin.com/company/thevibrant-group/" target="_blank" rel="noopener noreferrer">
    <img src={social1} alt="LinkedIn"/>
  </a>
  <a href="https://x.com/BeyondVibrantG" target="_blank" rel="noopener noreferrer">
    <img src={social2} alt="X (Twitter)"/>
  </a>
  <a href="https://www.youtube.com/@VibrantGroupLtd" target="_blank" rel="noopener noreferrer">
    <img src={social3} alt="YouTube"/>
  </a>
</div>
      </div>
      <div className="about-div about-div2">

      
      <h2 class="neon-text">We Are Way Beyond Good Data - The Real Actionable Data</h2>

      </div>
      {/* <div className="WHYUS">
        <h3 >WHY US</h3>
        <br />
        <h2>Why Vibrant</h2>
        <br />

        <p>
          Find and close your next customer before your competitors do with our
          all-in-one platform that tells you
          <br /> who to reach and how to reach them. With ZoomInfo, you can
          unite sales and marketing teams around a single
          <br /> source of truth. And you can scale faster by automating tasks
          across all outreach channels.
        </p>

        <br />
        <br />
        <br />
        <br />
        <div className="WHYUS-cards">
          <div className="WHYUS-card">
            <h3>Unlock Insights</h3>
            <br />
            <p>
              Find your next ideal customers with real-time insights backed by
              trusted data.
            </p>
          </div>
          <div className="WHYUS-card">
            <h3>Unlock Insights</h3>
            <br />
            <p>
              Find your next ideal customers with real-time insights backed by
              trusted data.
            </p>
          </div>
          <div className="WHYUS-card">
            <h3>Unlock Insights</h3>
            <br />
            <p>
              Find your next ideal customers with real-time insights backed by
              trusted data.
            </p>
          </div>
        </div>
      </div> */}
      <div className="OURSOLUTION">
 
    
        <div className="SOLUTION-cards">
          <Link  to='/dataresearch' className="SOLUTION-card-div none">
            <div className="SOLUTION-card">
              <div className="icon-div">
                <img src={icon} />
              </div>
              <h2>Bespoke Data Research</h2>
              <br />
              <p>
              An Intelligent Human Verified Research Insight That Helps You Get Connected With The Right Prospect At The Right Time
              </p>
            </div>
          </Link>
          <Link to='/crmcleansing' className="SOLUTION-card-div none">
            <div className="SOLUTION-card">
              <div className="icon-div">
                <img src={icon2} />
              </div>
              <h2>CRM Enrichment</h2>
              <br />
              <p>
              Get A Complete Picture Of Your Audience, Know Them Inside Out. Enrich & Enhance Your Existing Database With An Intelligent Research Insight.
              </p>
            </div>
          </Link>
          <Link to='/supply' className="SOLUTION-card-div none">
            <div className="SOLUTION-card">
              <div className="icon-div">
                <img src={icon3} />
              </div>
              <h2>Demand Generation</h2>
              <br />
              <p>
              It’s All About Demand & Supply – We Help You Outsmart Your Product Outreach & Directly Connect You With Your Targeted Audience
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div className="WHOWEARE">

     <div className="WHOWEARE-heading">
     <h2 className="neon-text">We Are All About Prospecting & Growth
  

    </h2>
     </div>
     
        {/* <h2>We are all about Prospecting & Growth</h2> */}
       <br/>
        <div className="WHOWEARE-cards">
          <div>
            <div className="WHOWEARE-card">
              <br />
          
              <h2>100%</h2>
             
              <h3>Assured Quality &
<br/>
               Compliance
              </h3>
              <br/>

              <p>
               Freshly crafted & precisely tailored to match your desired campaign requirements
              </p>
            </div>
          </div>
          <div>
            <div className="WHOWEARE-card">
              <br />
        
              <h2>100%</h2>
           
              <h3>Increase in Sales Engagement & Win Rates
            </h3>
            <br/>

              <p>
              Enriched with relevant & highly actionable insights, that it directly connects you with your ideal prospect
              </p>
            </div>
          </div>
          <div>
            <div className="WHOWEARE-card">
              <br />
           
              <h2> 100%</h2>
            
              
              <h3>Assured Savings on B2B Data Spends
              </h3>
<br/>
              <p>
               Simply save more win big, whether its time or money savings is assured.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials">
        <div className="testimonials-left">
      
          <h2>We have been trusted globally for our robust solutions that keep<br/> growing brands around the world! </h2>
        
       <br/>
          
        </div>
    
        <div className="testimonials-right">
        <div className="client-cards-wrapper">
        <div className="client-cards marquee">
        <div >

        <div className="client-card">
        <div className="client-heading">
   <div>
   <div className="clientlogo">
        <img src={c1}/>

        </div>
   </div>
<div>
  <h3>BlackRock </h3>
  <i>Multinational Investment Company</i>
</div>

        </div>
        <br/>
        <p>Team Vibrant helped us enrich our existing database., With all the research & insights they have infused in our CRM, we have already started experiencing growth in business engagements. Surprised to see how seamlessly our team gets connects with our ideal prospects. Their expertise and attention to detail are truly invaluable to our business.</p>


</div>
        </div>
        <div >
          
    <div className="client-card">
        <div className="client-heading">
        <div>

        <div className="clientlogo">
        <img src={c2}/>
        </div>

        </div>
<div>
  <h3>Accenture Interactive </h3>
  <i>Media Company</i>
</div>

        </div>
        <br/>
        <p>Most relevant & robust data ever experienced, love their straight & clear approach. The prospect list they delivered was full of insights & it so well aligned with our targeted audience - Simply Outstanding.</p>


</div>
        </div>

<div>
    <div className="client-card">
        <div className="client-heading">
        <div>
        <div className="clientlogo">
        <img src={c3}/>
</div>

        </div>
<div>
  <h3>Ogivly </h3>
  <i>Advertising Company</i>
</div>

        </div>
        <br/>
        <p>Since day 1 they started working as a member of our inhouse team, they knew what exactly our sales team was looking for. Highly impressed with the range of customization they offer, prospecting at its best. </p>


</div>
</div>
<div>

  <div className="client-card">
        <div className="client-heading">
        <div>
          
        <div className="clientlogo">
        <img src={c4}/>
        </div>

        </div>
<div>
  <h3>Nestle </h3>
  <i>World’s Largest Food & Beverage Company</i>
</div>

        </div>
        <br/>
        <p>The level of accuracy is outstanding – they are surely winning big at ACCURACY, AFFORDABILITY & RELIABILITY  </p>


</div>
</div>
<div>

  <div className="client-card">
        <div className="client-heading">
        <div>

        <div className="clientlogo">
        <img src={c5}/>
        </div>

        </div>
<div>
  <h3>Deloitte Digital </h3>
  <i>Leading Creative Media Consultancy</i>
</div>

        </div>
        <br/>
        <p>They certainly live up to their words – One of the best bespoke accounts list every experienced. I must say their innovative solutions are a real game changer when it comes to GTM strategies, as they delivered complete market insights right at our fingertips</p>


</div>
</div>
<div>

  <div className="client-card">
        <div className="client-heading">
        <div>

        <div className="clientlogo">
        <img src={c6}/>
        </div>

        </div>
<div>
  <h3>Zycus</h3>
  <i>Cognitive Procurement Software Company </i>
</div>

        </div>
        <br/>
        <p>Data at its best – It was precisely designed to match our desired campaign requirements, Kudos to their entire team.  </p>


</div>
</div>
 <div >

<div className="client-card">
<div className="client-heading">
<div>
<div className="clientlogo">
<img src={c1}/>

</div>
</div>
<div>
<h3>BlackRock </h3>
<i>Multinational Investment Company</i>
</div>

</div>
<br/>
<p>Team Vibrant helped us enrich our existing database., With all the research & insights they have infused in our CRM, we have already started experiencing growth in business engagements. Surprised to see how seamlessly our team gets connects with our ideal prospects. Their expertise and attention to detail are truly invaluable to our business.</p>


</div>
</div>
<div >
  
<div className="client-card">
<div className="client-heading">
<div>

<div className="clientlogo">
<img src={c2}/>
</div>

</div>
<div>
<h3>Accenture Interactive </h3>
<i>Media Company</i>
</div>

</div>
<br/>
<p>Most relevant & robust data ever experienced, love their straight & clear approach. The prospect list they delivered was full of insights & it so well aligned with our targeted audience - Simply Outstanding.</p>


</div>
</div>

<div>
<div className="client-card">
<div className="client-heading">
<div>
<div className="clientlogo">
<img src={c3}/>
</div>

</div>
<div>
<h3>Ogivly </h3>
<i>Advertising Company</i>
</div>

</div>
<br/>
<p>Since day 1 they started working as a member of our inhouse team, they knew what exactly our sales team was looking for. Highly impressed with the range of customization they offer, prospecting at its best. </p>


</div>
</div>
<div>

<div className="client-card">
<div className="client-heading">
<div>
  
<div className="clientlogo">
<img src={c4}/>
</div>

</div>
<div>
<h3>Nestle </h3>
<i>World’s Largest Food & Beverage Company</i>
</div>

</div>
<br/>
<p>The level of accuracy is outstanding – they are surely winning big at ACCURACY, AFFORDABILITY & RELIABILITY  </p>


</div>
</div>
<div>

<div className="client-card">
<div className="client-heading">
<div>

<div className="clientlogo">
<img src={c5}/>
</div>

</div>
<div>
<h3>Deloitte Digital </h3>
<i>Leading Creative Media Consultancy</i>
</div>

</div>
<br/>
<p>They certainly live up to their words – One of the best bespoke accounts list every experienced. I must say their innovative solutions are a real game changer when it comes to GTM strategies, as they delivered complete market insights right at our fingertips</p>


</div>
</div>
<div>

<div className="client-card">
<div className="client-heading">
<div>

<div className="clientlogo">
<img src={c6}/>
</div>

</div>
<div>
<h3>Zycus</h3>
<i>Cognitive Procurement Software Company </i>
</div>

</div>
<br/>
<p>Data at its best – It was precisely designed to match our desired campaign requirements, Kudos to their entire team.  </p>


</div>
</div>
 <div >

<div className="client-card">
<div className="client-heading">
<div>
<div className="clientlogo">
<img src={c1}/>

</div>
</div>
<div>
<h3>BlackRock </h3>
<i>Multinational Investment Company</i>
</div>

</div>
<br/>
<p>Team Vibrant helped us enrich our existing database., With all the research & insights they have infused in our CRM, we have already started experiencing growth in business engagements. Surprised to see how seamlessly our team gets connects with our ideal prospects. Their expertise and attention to detail are truly invaluable to our business.</p>


</div>
</div>
<div >
  
<div className="client-card">
<div className="client-heading">
<div>

<div className="clientlogo">
<img src={c2}/>
</div>

</div>
<div>
<h3>Accenture Interactive </h3>
<i>Media Company</i>
</div>

</div>
<br/>
<p>Most relevant & robust data ever experienced, love their straight & clear approach. The prospect list they delivered was full of insights & it so well aligned with our targeted audience - Simply Outstanding.</p>


</div>
</div>

<div>
<div className="client-card">
<div className="client-heading">
<div>
<div className="clientlogo">
<img src={c3}/>
</div>

</div>
<div>
<h3>Ogivly </h3>
<i>Advertising Company</i>
</div>

</div>
<br/>
<p>Since day 1 they started working as a member of our inhouse team, they knew what exactly our sales team was looking for. Highly impressed with the range of customization they offer, prospecting at its best. </p>


</div>
</div>
<div>

<div className="client-card">
<div className="client-heading">
<div>
  
<div className="clientlogo">
<img src={c4}/>
</div>

</div>
<div>
<h3>Nestle </h3>
<i>World’s Largest Food & Beverage Company</i>
</div>

</div>
<br/>
<p>The level of accuracy is outstanding – they are surely winning big at ACCURACY, AFFORDABILITY & RELIABILITY  </p>


</div>
</div>
<div>

<div className="client-card">
<div className="client-heading">
<div>

<div className="clientlogo">
<img src={c5}/>
</div>

</div>
<div>
<h3>Deloitte Digital </h3>
<i>Leading Creative Media Consultancy</i>
</div>

</div>
<br/>
<p>They certainly live up to their words – One of the best bespoke accounts list every experienced. I must say their innovative solutions are a real game changer when it comes to GTM strategies, as they delivered complete market insights right at our fingertips</p>


</div>
</div>
<div>

<div className="client-card">
<div className="client-heading">
<div>

<div className="clientlogo">
<img src={c6}/>
</div>

</div>
<div>
<h3>Zycus</h3>
<i>Cognitive Procurement Software Company </i>
</div>

</div>
<br/>
<p>Data at its best – It was precisely designed to match our desired campaign requirements, Kudos to their entire team.  </p>


</div>
</div>

        </div>
        </div>
        </div>
      </div>
      <div className="Contact-div">
        <p>Join Us</p>
        <br />
        <h2>
        Being completely digital we also embrace the modern technologies these days that truly helps us deliver exponential values to our clients, and allows us the freedom to spend more time with our loved ones.
        </h2>
        <br />
        <br />
      <Link to='/contact'>


        <button>Contact Us</button>
      </Link>
      </div>
      <Footer />
    </>
  );
}

export default Home;
