import React, { useEffect } from "react";
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/privacy.png'
import about from '../Assets/Screenshot_2024-09-14_124521-removebg-preview.png'



function Privacy() {

  return (
    <>
          <Navbar/>
          <div className='about-banner about-banner-plain about-banner-Privacy'>

<h1>PRIVACY POLICY</h1>

<br/>


<p>With, VIBRANT GROUP LIMITED your privacy is in safe hands. We truly respect your privacy & will take utmost care to protect your information that we consume or may store with us for research & marketing purposes. Our privacy policy describes how we will be managing your collected & received information. Any element of your information that we process or may store is either directly received from you in the form of verbal communication or has been sourced/obtained from publicly available domains, such as social media platforms, business magazine’s & online-offline published articles.</p>
<br/>
<p>WE DON’T SOURCE OR STORE ANY OF YOUR FINANCIAL AND CONFIDENTIAL INFORMATION
We don’t store any of your information while visiting our website HOWEVER, if you opt to download, request trial run, call back, product demo or any business inquiry may led us to collect your information.
</p>



    </div>
    <div className='about-div about-div3'>
    <div className='about-div-right'>
<img src={about} data-aos="zoom-in"/>
</div>
<div className='about-div-left about-div-left2'>

<h2><span>Obtaining & </span><br/> Sharing Your Information</h2>
<h4>Information researched and stored with us includes: Complete Name, Business/Company Name, Company Details, Corporate Address, Designated Job Titles, Active Phone & Mobile Numbers, Valid Email Address and basic supplementary information which is available on different sources including Corporate Websites, Annual Reports, Social Networks, Tradeshows, Conferences, User Groups, Publications, Associations, News Releases, Technical Papers, List Directories, Communities, Blogs, Press Releases, Company Reports, Trade Magazines, Journals, Newsletters, Professional and Social Networking Sites, Search Engines, etc.
‍
Stored & collected information is made available to our customers for strategic business engagements & marketing purposes.
</h4>

</div>

</div>
<Footer/>

    </>
  )
}

export default Privacy