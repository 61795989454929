import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import DataResearch from "./Pages/DataResearch";
import CRMCleansing from "./Pages/CRMCleansing";
import DataVaild from "./Pages/DataVaild";
import GreenRevolution from "./Pages/GreenRevolution";
import Supply from "./Pages/Supply";
import Support from "./Pages/Support";
import Process from "./Pages/Process";
import Privacy from "./Pages/Privacy";
import 'bootstrap/dist/css/bootstrap.css'
  import Aos from 'aos'
import 'aos/dist/aos.css'
import Career from "./Pages/Career";
import ScrollToTop from "./Pages/ScrollToTop";
function App() {



    useEffect(()=>{
        Aos.init({duration: 2000});
    })
  return (
    <>
    <Router>
    <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/dataresearch" element={<DataResearch/>} />
            <Route path="/crmcleansing" element={<CRMCleansing/>} />
            <Route path="/datavalid" element={<DataVaild/>} />
            <Route path="/greenrevolution" element={<GreenRevolution/>} />
            <Route path="/supply" element={<Supply/>} />
            <Route path="/support" element={<Support/>} />
            <Route path="/process" element={<Process/>} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/career" element={<Career/>} />










            
            
          </Routes>
        </Router>
    
    </>
  );
}

export default App;
