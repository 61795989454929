import React, { useState ,useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from '../Components/Navbar'
import img from '../Assets/cleanbn.png'
import img2 from '../Assets/image 110.png'
import img3 from '../Assets/crm (1).png'
import img4 from '../Assets/crm (2).png'
import img5 from '../Assets/crm (3).png'

import Footer from '../Components/Footer'
import { Modal ,ModalBody ,ModalHeader } from 'reactstrap';

function CRMCleansing() {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Get form data
    const name = form.current.user_name.value.trim();
    const mobile = form.current.user_mobile.value.trim();
    const email = form.current.user_email.value.trim();
    const option = form.current.user_option.value.trim();
    const message = form.current.user_message.value.trim();

    // Form validation
    if (!name) {
      window.alert("Name is required");
      return;
    }

    if (!mobile) {
      window.alert("Mobile number is required");
      return;
    }

    if (!/^\d{10}$/.test(mobile)) {
      window.alert("Please enter a valid 10-digit mobile number");
      return;
    }

    if (!email) {
      window.alert("Email is required");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      window.alert("Please enter a valid email address");
      return;
    }

    if (!option) {
      window.alert("Please select an option");
      return;
    }

    if (!message) {
      window.alert("Message is required");
      return;
    }

    // If validation passes, send the email
    emailjs
      .sendForm('service_f3vhlz3', 'template_0fj88re', form.current, {
        publicKey: 'aBpUGhBmYAwpD_7Cz',
      })
      .then(
        () => {
          form.current.reset();
          window.alert('Thank you for submitting your message!');
        },
        (error) => {
          window.alert('Oops! Something went wrong. Please try again.');
        }
      );
  };
    const [modal , setModal] = useState(false)
    const toggleModal = () => setModal(!modal);
  return (
    <>
       <Modal size="lg" isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
      Experience The Change, That Accelerate Your ROI
      </ModalHeader>
      <ModalBody className='modal-a'>
      <form ref={form} onSubmit={sendEmail}>

       <div className='modal-ips'>
      <div className='mpdal-ip'>
      <label>Name</label>
       <br/>
       <input name='user_name' placeholder='Enter Your Name' />
      </div>
     
       <div  className='mpdal-ip'>

       <label>Title</label>
       <br/>
       <input name='user_title' placeholder='Enter Your Title' />
       </div>

       </div>
<div className='modal-ips'>
<div className='mpdal-ip'>
<label>Phone</label>
       <br/>
       <input name='user_mobile' placeholder='9874563210' />
</div>
       
       <div className='mpdal-ip'>

       <label>Email</label>
       <br/>
       <input name='user_email' placeholder='Enter Your Email' />
       </div>

</div>

<br/>
<div className='modal-ips'>

       
       <div className='mpdal-ip'>

       <label for="options">Choose an option:</label>
       <br/>
     
<select id="options" name='user_option'>

            <option value=''>--Please select an option--</option>
            <option value='Data Research'><li>Data Research</li></option>
            <option value='Market Research'>- Market Research</option>

            <option value='Targeted Account List'>Targeted Account List</option>
            <option value='Prospect List'>Prospect List</option>
            <option value='Technographics'>Technographics</option>
        





            <option value='CRM Cleansing'>- CRM Cleansing</option>

            <option value='Data Append'>Data Append</option>
            <option value='Data Enrichment'>Data Enrichment</option>
            <option value='Data Unification'>Data Unification</option>




            <option value='Data Validation'>- Data Validation</option>
            <option value='Web Verified'>Web Verified</option>
            <option value='Phone Verified'>Phone Verified</option>



            <option value='Demand Generation'><li>- Demand Generation</li></option>
            <option value='Virtual Assistanc'>- Virtual Assistance</option>
       


         
</select>
       </div>

</div>
<br/>
       
      

       <label>Messages</label>
       <br/>
       <input name='user_message' placeholder='Enter Your Messages' />
       <br/>
       <button type="submit">Submit</button>
       </form>

      </ModalBody>
    </Modal>
    <Navbar/>
    <div className='about-banner about-banner-plain'>
<div className='about-banner-left crm'>
<h4><span>CRM Cleansing</span></h4>
<br/>
<p>Get your CRM data systematically organized & eliminate the bad data from your system. This proactive approach ensures that your CRM data is clean, up-to-date and accurate with latest prospect updates including their direct mobile numbers which is crucial for the success of sales and marketing efforts. CRM cleansing not only organises your existing data but also enrich & transform the irrelevant & incomplete records into robust & actionable insights. Our CRM Cleansing covers the complete process right from appending, enriching to unification.   </p>
<br/>
<br/>
<button onClick={toggleModal}>Try For Free</button>
</div>
<div className='about-banner-right'>

<img src={img} data-aos="fade-down"/>
</div>

    </div>

    <div className='targeted'>
    <div className='targeted-div'>
    <div className='targeted-left'>
    <h2>Data Append</h2>
    
    <br/>
    <p>Stay ahead with latest updates & complete customer information. Get your missing CRM data fields updated with complete & accurate information to optimize your team’s outreach. </p>
    <br/>
    <button onClick={toggleModal}>Try For Free - Get Your Data Audited </button>
</div>
 <div className='targeted-right'>
 <img src={img3} data-aos="zoom-in"/>

</div>
</div>
 <div className='targeted-div targeted-div2'>
 <div className='targeted-right targeted-right2'>
 <img src={img4} data-aos="zoom-in"/>

</div>
    <div className='targeted-left'>
    <h2>Data Enrichment</h2>
    
    <br/>
    <p>Not just merely any data, enrich & enhance your existing database with an intelligent research insight. Get a complete picture of your audience, know them inside out.  </p>
    <br/>
    <button onClick={toggleModal}>Try For Free - Get Your Data Optimized </button>
</div>

</div>
 <div className='targeted-div'>
    <div className='targeted-left'>
    <h2>Data Unification</h2>
    
    <br/>
    <p>Integrate smart intelligence across your CRM data to create an up-to-date unified dataset. Get your complete CRM data audited for de-duplication & further normalized to obtain standardize view throughout the dataset.</p>
    <br/>
    <button onClick={toggleModal}>Try For Free - Get Your Data Organized </button>
</div>
 <div className='targeted-right'>
 <img src={img5} data-aos="zoom-in"/>

</div>
</div> 
    </div>
    <Footer/>
   </>
  )
}

export default CRMCleansing