import React from 'react'
import Navbar from '../Components/Navbar'
import img from '../Assets/processbn.png'
import icon from '../Assets/Group 6414545.png'
import Footer from '../Components/Footer'
function Process() {
  return (
    <>
        <Navbar/> 
        <div className='about-banner about-banner-plain'>
<div className='about-banner-left about-banner-left2'>
<h1>The Process</h1>

<br/>


<h5>What it Takes & How its Done?</h5>

</div>
<div className='about-banner-right'>

<img src={img} data-aos="zoom-in"/>
</div>

    </div>
    {/* <div className='Evaluation' data-aos="zoom-in">
    <div className='Evaluation-1'>
<img src={icon}/>
    </div>
    <div className='Evaluation-2'>
    <div className='ev-box'>
    <div className='ev-box-circle'>
    <h3>1 <br/>Step</h3>

</div>
<p>Evaluation</p>
    </div>

</div>
  <div className='Evaluation-3'>
  <div className='ev-text'>
  <p>We start any projects by evaluating and understanding the requirements/specifications completely, so we are on the same page with the clients desired requirements.</p>

  </div>

</div>

    </div>
    <div className='Evaluation' data-aos="zoom-in">
    <div className='Evaluation-3'>
  <div className='ev-text'>
  <p>Before we commit to any project our expert research team conduct a quick feasibility check to ascertain if the targets can be achieved as expected by client. </p>

  </div>

</div>
 
    <div className='Evaluation-2'>
    <div className='ev-box'>
    <div className='ev-box-circle'>
    <h3>2 <br/>Step</h3>

</div>
<p>Feasibility</p>
    </div>

</div>
    <div className='Evaluation-1'>
<img src={icon}/>
    </div>

    </div>
    <div className='Evaluation' data-aos="zoom-in">
    <div className='Evaluation-1'>
<img src={icon}/>
    </div>
    <div className='Evaluation-2'>
    <div className='ev-box'>
    <div className='ev-box-circle'>
    <h3>3 <br/>Step</h3>

</div>
<p>Research</p>
    </div>

</div>
  <div className='Evaluation-3'>
  <div className='ev-text'>
  <p>With a green signal from the client, research work is started immediately by scanning through tons of pages on the internet, Linkedin, along with other social platforms. Based on clients specified criteria all the available relevant data is sourced on a real time basis from multiple channels with the help of modern technologies.

</p>

  </div>

</div>

    </div>
    <div className='Evaluation' data-aos="zoom-in">
    <div className='Evaluation-3'>
  <div className='ev-text'>
  <p>The Data is then thoroughly segregate & standardize exactly as per the desired requirements. Passes through multiple layers of validations (Primary & Secondary) to gain optimum results. </p>

  </div>

</div>
 
    <div className='Evaluation-2'>
    <div className='ev-box'>
    <div className='ev-box-circle'>
    <h3>4 <br/>Step</h3>

</div>
<p>Validation & Enrichment of Data</p>
    </div>

</div>
    <div className='Evaluation-1'>
<img src={icon}/>
    </div>

    </div>
    <div className='Evaluation' data-aos="zoom-in">
    <div className='Evaluation-1'>
<img src={icon}/>
    </div>
    <div className='Evaluation-2'>
    <div className='ev-box'>
    <div className='ev-box-circle'>
    <h3>5 <br/>Step</h3>

</div>
<p>Quality and Compliance</p>
    </div>

</div>
  <div className='Evaluation-3'>
  <div className='ev-text'>
  <p>Before the data is delivered to the client it goes through rigorous quality and compliance checks where our quality expert performs final quality audits to make sure every component of the data is validated & aligns exactly with clients desired requirements.

</p>

  </div>

</div>

    </div> */}




  <div className='theprocess'>
  <div className='hax' data-aos="zoom-in">
    <div className='hax2' data-aos="zoom-in">
    <div className='steps'>
<p>1</p>

    </div>
<h3>Evaluation</h3>
<h5>We start any projects by evaluating and understanding the requirements/specifications completely, so we are on the same page with the clients desired requirements.</h5>


</div>
    </div>
    <div className='hax' data-aos="zoom-in">
    <div className='hax2' data-aos="zoom-in">
    <div className='steps'>
<p>2</p>

    </div>
<h3>Feasibility</h3>
<h5>Before we commit to any project our expert research team conduct a quick feasibility check to ascertain if the targets can be achieved as expected by client.</h5>


</div>
    </div>
    <div className='hax' data-aos="zoom-in">
    <div className='hax2' data-aos="zoom-in">
    <div className='steps'>
<p>3</p>

    </div>
<h3>Research</h3>
<h5>With a green signal from the client, research work is started immediately by scanning through tons of pages on the internet, Linkedin, along with other social platforms. Based on clients specified criteria all the available relevant data is sourced on a real time basis from multiple channels with the help of modern technologies.</h5>


</div>
    </div>
    <div className='hax' data-aos="zoom-in">
    <div className='hax2' data-aos="zoom-in">
    <div className='steps'>
<p>4</p>

    </div>
<h3>Validation & Enrichment of Data</h3>
<h5>The Data is then thoroughly segregate & standardize exactly as per the desired requirements. Passes through multiple layers of validations (Primary & Secondary) to gain optimum results.</h5>


</div>
    </div>
    <div className='hax' data-aos="zoom-in">
    <div className='hax2' data-aos="zoom-in">
    <div className='steps'>
<p>5</p>

    </div>
<h3>Quality and Compliance</h3>
<h5>Before the data is delivered to the client it goes through rigorous quality and compliance checks where our quality expert performs final quality audits to make sure every component of the data is validated & aligns exactly with clients desired requirements.</h5>


</div>
    </div>
  </div>
    <Footer/>
    </>
  )
}

export default Process